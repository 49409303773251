<template>
  <div class="repair-parameters-wrapper">
    <el-form :model="tourConfig" :rules="tourConfigRules" ref="tourConfigForm" class="main" size="medium" label-width="135px" :label-position="'right'">
      <div class="form-block">
        <h3>工单配置</h3>
        <el-divider></el-divider>
        <div class="form-group">
          <label class="left-label">结束节点配置：</label>
          <el-row>
            <el-col :span="8">
              <el-form-item label="工单自动评价：" prop="evaluationTimeout">
                <el-input v-model.number="tourConfig.evaluationTimeout" @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                <span class="colorGrey pdl5">小时</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工单超时提醒：" prop="handleTimeout">
                <el-input v-model.number="tourConfig.handleTimeout" @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                <span class="colorGrey pdl5">天</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div class="footer button-group fixed-bottom-btn">
      <el-button class="btn-submit" size="medium" type="primary" @click="submit" :loading="isSubmitting">保存</el-button>
    </div>
  </div>
</template>

<script>
const validateNumber = function(min,max){
  let validator = (rule,value,callback)=>{
    if(value<min){
      return callback(new Error(`不能小于${min}`))
    }else if(max&&value>max){
      return callback(new Error(`不能大于${max}`))
    }else{
      callback()
    }
  }
  return  validator
}
export default {
  name: "tourParameterConfig",
  data(){
    return {
      tourConfig:{
        evaluationTimeout:2,
        handleTimeout:3
      },
      isSubmitting:false,
      tourConfigRules:{
        evaluationTimeout:[this.$formValidator.empty('自动评价时间','blur','number'),{ validator:validateNumber(1,99), trigger:'blur',type:'number'}],
        handleTimeout:[this.$formValidator.empty('超时时间','blur','number'),{ validator:validateNumber(1,99), trigger:'blur',type:'number'}]
      }
    }
  },
  mounted(){
    this.getRepairParameters()
  },
  methods:{
    submit(){
      this.$refs.tourConfigForm.validate(valid=>{
        if(valid){
          let param =  {...this.tourConfig}
          this.isSubmitting=true;
          this.$api.tour.editTourConfig(param).then(res=>{
            this.$message.success('保存成功');
            this.isSubmitting=false;
          }).catch(err=>{
            this.isSubmitting=false;
          })
        }
      })
    },

    getRepairParameters(){
      this.$api.tour.getTourConfig().then(res=>{
        this.tourConfig = res.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-step__head.is-finish {
  color: $subjectColor;
  border-color: $subjectColor;
}
::v-deep .el-step__title.is-finish {
  color:$subjectColor;
}
.el-divider--horizontal{
  margin:18px 0;
}
.el-input,.el-select {
  width: 200px;
}
.el-step__description .el-input{
  width: 90px;
  padding-left: 27px;
}
.el-step__description label{
  width: 100px;
}
::v-deep .el-step__title{
  font-size: 14px;
  &.is-process{
    font-weight:normal;
    color:#C0C4CC !important;
  }
}
.repair-parameters-wrapper{
  .main{
    background: #fff;
    padding: 18px;
    border-radius: 2px;
    height: calc(100% - 100px);
    .form-block{
      .form-group{
        .left-label{
          width: 135px;
          display: inline-block;
          text-align: right;
          padding-right: 12px;
          margin-top: 18px;
        }
        .step-block{
          width: 800px;
          margin: 12px 0 15px 0;
          display: inline-block;
          vertical-align: top;
          .is-finish{
            .solid-ring{
              border: 1px solid $subjectColor;
              background:$subjectColor;
              &:before{
                border: 1px solid $subjectColor;
              }
            }
          }
          .is-process{
            .solid-ring{
              border: 1px solid #C0C4CC ;
              &:before{
                border: 1px solid #C0C4CC ;
              }
            }
          }
          .solid-ring{
            width: 8px;
            height: 8px;
            border: 1px solid #C0C4CC;
            background: #fff;
            border-radius: 50%;
            cursor: pointer;
            position: relative;

            &:before{
              content: '';
              position: absolute;
              top: -5px;
              left: -5px;
              width: 16px;
              height: 16px;
              border: 1px solid #C0C4CC;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .footer{
    text-align: center;
  }
}
</style>